.group--conjunctions, .group--children {
  margin: 0px !important;
}

.rule {
  padding: 5px;
}

.group {
  padding-left: 0px;
}

.rule_group {
  padding-left: 5px;
}

.group--children {
  padding-left: 10px !important;
}
.group--children > .group-or-rule-container > .group-or-rule::before, .group--children > .group-or-rule-container > .group-or-rule::after {
  left: -7px;
  width: 7px;
}
.group--children > .group-or-rule-container:first-child > .group-or-rule::before {
  top: -7px;
}

.group--conjunctions {
  margin-left: 4px !important;
}

.group-or-rule-container {
  margin-top: 5px;
  margin-bottom: 5px;
  padding-right: 5px;
}

.group--children {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.group--header,
.group--footer {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.rule--operator, .widget--widget, .widget--valuesrc, .widget--sep {
  margin-left: 5px;
}

.rule_group .group--actions {
  margin-left: 5px;
}
.rule_group .rule_group--children {
  padding-left: 10px !important;
}
.rule_group .rule_group--children.one--child {
  padding-left: 5px !important;
}
.rule_group .rule_group--children > .group-or-rule-container > .group-or-rule::before, .rule_group .rule_group--children > .group-or-rule-container > .group-or-rule::after {
  left: -7px;
  width: 7px;
}

.widget--valuesrc {
  margin-right: -3px;
}

.operator--options--sep {
  margin-right: 5px;
}

.group--header {
  padding-left: 0;
  padding-right: 5px;
  margin: 0;
}

.group--drag-handler {
  margin-left: 5px;
}

.rule--func--arg-value > .rule--widget {
  margin-left: -5px;
}
